import React, { Component } from "react";

export default class Error extends Component {
    render() {
        return (
            <div className="error">
                <h1>Error: 404</h1>
                <h1>Page not Fond</h1>
            </div>
        );
    }
}
